import { Link as RNLink } from '@inertiajs/react';
import { cn } from '@/lib/utils';
export default function Link({
    href,
    children,
    className = '',
    target,
    isExternal = false,
    ...props
}: any) {
    return isExternal ? (
        <a href={href} target={target ?? '_blank'} className={cn('cursor-pointer ', className)}>
            {children}
        </a>
    ) : (
        <RNLink href={href} className={cn('cursor-pointer ', className)} {...props}>
            {children}
        </RNLink>
    );
}
